'use strict';

/**
 * @module utils
 */

// Imports
const { isEmpty } = require('lodash');

// Constants
var utils = {};

var CONFIG = {
    CLASS: {
        D_NONE: 'd-none'
    }
};

/**
 * @function displayAlert
 * @description Removes the d-none class from an element and then adds it back after a specified delay.
 * @param {string} element - Element to add the class to.
 * @param {number} delay - The delay in milliseconds before the class is added.
 */
utils.displayAlert = function (element, delay) {
    $(element).removeClass(CONFIG.CLASS.D_NONE);
    setTimeout(function () {
        $(element).addClass(CONFIG.CLASS.D_NONE);
    }, delay);
};

/**
 * @function isValid
 * @description Checks whether the provided data is not empty, not null and not undefined.
 * @param {any} data - The data to be validated
 * @returns {boolean} - Returns true if the data is not empty, not null, and not undefined. Returns false otherwise.
 */
utils.isValid = function (data) {
    if (!isEmpty(data) && data !== null && data !== undefined) {
        return true;
    }
    return false;
};

module.exports = utils;
