'use strict';

// Import base file to be extended
var base = require('base/components/clientSideValidation');

/**
 * Attach a new custm listener named as 'custom-invalid-input', that
 * forces the trigger of an input to be invalid.
 * It is possible to provide a custom message to be shown.
 */
base.customValidation = function () {
    $('form input, form select').on('custom-invalid-input', function (e, _message) {
        e.preventDefault();

        // Variables
        const message = _message || '';

        if (typeof message === 'string') {
            // 1. Set error message
            this.setCustomValidity(message);

            // 2. Get error that was set
            var validationMessage = this.validationMessage;

            // 3. Trigger invalid input
            $(this).addClass('is-invalid');
            $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
        }
    });
};

module.exports = base;
