'use strict';

/**
 * Imports
 */
var base = require('deborla/product/base');
var utils = require('../utils/utils');

/**
 * Component configuration constants
 */
const CONFIG = {
    ATTRIBUTES: {
        MAX: 'max'
    },
    BASE: {
        DECIMAL: 10
    },
    CLASS: {
        D_NONE: 'd-none',
        DECREASE_QUANTITY: 'decrease-quantity',
        ICON__DISABLED: 'icon--disabled',
        INCREASE_QUANTITY: 'increase-quantity'
    },
    ID: {
        QUANTITY_ERROR: '#quantity-error'
    },
    SELECTORS: {
        DECREASE_QUANTITY: '.decrease-quantity',
        DETAIL_QUANTITY: '.detail-quantity',
        ICON__DISABLED: '.icon--disabled',
        QUANTITY_SELECT: '.quantity-select'
    }
};

/**
 * Handle any change on the quantity selector
 */
base.updateQuantity = function () {
    // Variables
    var maxQuantity = $(CONFIG.SELECTORS.DETAIL_QUANTITY).attr(CONFIG.ATTRIBUTES.MAX);
    var parsedMaxQuantity = utils.isValid(maxQuantity) ? parseInt(maxQuantity, CONFIG.BASE.DECIMAL) : null;

    $('body').on('click', '[data-animation]', function (e) {
        e.preventDefault();

        // Variables
        var productQuantityInput = $(this).siblings(CONFIG.SELECTORS.QUANTITY_SELECT);
        var quantity = productQuantityInput.val();
        var parsedQuantity = utils.isValid(quantity) ? parseInt(quantity, CONFIG.BASE.DECIMAL) : null;

        if (parsedMaxQuantity && parsedQuantity) {
            // 1. Increase quantity
            // 2. Decrease quantity
            if ($(this).hasClass(CONFIG.CLASS.INCREASE_QUANTITY)) {
                // 1. Show error message if input value is already equal ou greater than the max quantity available
                // 2. Else... add 1 to the productQuantityInput
                if (parsedQuantity >= parsedMaxQuantity) {
                    utils.displayAlert(CONFIG.ID.QUANTITY_ERROR, 4000);
                } else {
                    productQuantityInput.val(parsedQuantity + 1);
                    if (parsedQuantity === 1) {
                        $(this).siblings(CONFIG.SELECTORS.ICON__DISABLED).removeClass(CONFIG.CLASS.ICON__DISABLED);
                    }
                }
            } else if ($(this).hasClass(CONFIG.CLASS.DECREASE_QUANTITY) && quantity > 1) {
                productQuantityInput.val(parsedQuantity - 1);
                if (parsedQuantity - 1 === 1) {
                    $(this).addClass(CONFIG.CLASS.ICON__DISABLED);
                }
            }
        }
    });

    // Listen to any change in the input field
    $(CONFIG.SELECTORS.QUANTITY_SELECT).change(function () {
        // Variables
        var quantity = $(this).val();
        var parsedQuantity = utils.isValid(quantity) ? parseInt(quantity, CONFIG.BASE.DECIMAL) : null;

        if (parsedMaxQuantity && parsedQuantity) {
            // 1. Disable minus button if input value is less ou equal than 1 and set input value to 1
            // 2. Show error message if input value is greater than the max quantity available
            // 3. Else... if Quantity is between 2 and max Quantity
            if (parsedQuantity <= 1) {
                $(this).val(1);
                $(this).siblings(CONFIG.SELECTORS.DECREASE_QUANTITY).addClass(CONFIG.CLASS.ICON__DISABLED);
            } else if (parsedQuantity > parsedMaxQuantity) {
                $(this).val(parsedMaxQuantity);
                utils.displayAlert(CONFIG.ID.QUANTITY_ERROR, 4000);
            } else {
                $(this).val(parsedQuantity);
                $(this).siblings(CONFIG.SELECTORS.ICON__DISABLED).removeClass(CONFIG.CLASS.ICON__DISABLED);
            }
        }
    });
};

module.exports = base;
