/**
 * Original file from app_deborla cartridge
 * Modifications:
 * - add updateFilterURL function
 */

'use strict';

/**
 * Imports
 */
var base = require('deborla/search/search');
var states = require('improove/util/states');

/**
 * wishlist
 * @function
 */
function validateWishlist() {
    var products = $('.product');
    var lengthProducts = $(products).length;
    for (var i = 0; i < lengthProducts; i++) {
        var url = $($(products)[i]).find('.wishlistTile').attr('data-href');
        var idProduct = $($(products)[i]).find('.wishlistTile').attr('data-idProductWishlist');
        var isVisited = $($(products)[i]).find('.icon-full-heart').length;

        if (url && isVisited === 0) {
            $.ajax({
                url: url,
                type: 'post',
                async: false,
                dataType: 'json',
                data: {
                    idproduct: idProduct
                },
                // eslint-disable-next-line no-loop-func
                success: function (data) {
                    $($(products)[i]).find('.wishlistTile span').addClass('heart-active');
                    if (data.isInWishlist) {
                        $($(products)[i]).find('.wishlistTile span').addClass('icon-full-heart');
                        $($(products)[i]).find('.wishlistTile span').removeClass('icon-wishlist');
                    }
                }
            });
        }
    }
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {JQuery<HTMLELement>} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {JQuery<HTMLELement>} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');

        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));

        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    ['.grid-header', '.header-bar', '.header.page-title', '.product-grid', '.show-more', '.filter-bar'].forEach(
        function (selector) {
            updateDom($results, selector);
        }
    );

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * Differ from app_deborla cartridge
 * Appends a filter URL to the current URL and updates the browser's history.
 * @function
 * @param {string} url - The URL to be appended as a filter.
 */
function updateFilterURL(url) {
    // creating a new URL object using the current origin and the url passed
    var filteredUrl = new URL(window.location.origin + url);

    // creating a URLSearchParams object from the search property of the filteredUrl object
    var searchParams = new URLSearchParams(filteredUrl.search);

    // concatenating the current pathname with the updated search parameters
    var newUrl = window.location.pathname + '?' + searchParams;

    // updating the browser's history with the new url
    history.pushState(null, document.title, newUrl);
}

/**
 * Handle filter selection
 */
base.initSearch = function ({
    stateRefinement = 'refinement',
    refinementContainerSelector = '.state-container--refinements',
    refinementTriggerSelector = '.refinements-state-trigger',
    applyFilterSelector = '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
    applyFilterContainer = '.container',
    statesUtil = states
} = {}) {
    const $refinementStateVariable = stateRefinement;
    const $refinementContainerElement = $(refinementContainerSelector);
    const $refinementTriggerElement = $(refinementTriggerSelector);
    const $applyFilterContainer = $(applyFilterContainer);

    statesUtil.add($refinementStateVariable, $refinementContainerElement, $refinementTriggerElement, {});

    $applyFilterContainer.on('click', applyFilterSelector, function (e) {
        e.preventDefault();
        e.stopPropagation();
        $.spinner().start();
        $(this).trigger('search:filter', e);
        $.ajax({
            url: $(this).data('href'),
            data: {
                page: $('.grid-footer').data('page-number'),
                selectedUrl: $(this).data('href')
            },
            method: 'GET',
            success: (response) => {
                window.scrollTo(0, 0);
                parseResults(response);
                updateFilterURL($(this).data('href'));
                $.spinner().stop();
                validateWishlist();
            },
            error: () => {
                $.spinner().stop();
            }
        });
    });
};

module.exports = base;
