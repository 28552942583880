'use strict';

// function window_resize() {
//     if (document.getElementsByClassName('mainbanner') != undefined && document.getElementsByClassName('mainbanner')[0] != undefined && document.getElementById('main_image') != undefined) {
//         var mainBanner = document.getElementsByClassName('mainbanner')[0];
//         var mainImage = document.getElementById('main_image');
//         var firstImage = mainImage.getElementsByClassName('column-widget-first');
//         var secondImage = mainImage.getElementsByClassName('column-widget-second');

//         if (window.innerWidth >= 1024 && mainImage && mainImage.getElementsByClassName('column-widget-first') != undefined && mainImage.getElementsByClassName('column-widget-second') != undefined) {
//             mainBanner.setAttribute('style', `height: ${firstImage[0].clientHeight + secondImage[0].clientHeight + 20}px`);
//             mainImage.getElementsByClassName('component-image')[0].style.height = firstImage[0].clientHeight + secondImage[0].clientHeight + 'px';
//         } else if (mainImage != undefined && mainImage.getElementsByClassName('column-widget-first') != undefined && mainImage.getElementsByClassName('column-widget-second') != undefined) {
//             if (mainImage.getElementsByClassName('component-image')[0].clientHeight > 0) {
//                 mainBanner.setAttribute('style', `height: ${firstImage[0].clientHeight + mainImage.getElementsByClassName('component-image')[0].clientHeight }px`);
//             } else {
//                 mainBanner.setAttribute('style', `height: ${firstImage[0].clientHeight + mainImage.getElementsByClassName('component-image')[1].clientHeight }px`);
//             }
//         }
//     }
// }

// $(document).ready(() => {
//     window_resize();
// });


// $(window).resize(() => {
//     window_resize();
// });
