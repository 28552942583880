window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('deborla/components/menu'));
    processInclude(require('deborla/components/countrySelector'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('deborla/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('deborla/components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('./product/base'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('deborla/components/tabs'));
    processInclude(require('improove/components/collapsibleItem'));
    processInclude(require('improove/util/states'));
    processInclude(require('deborla/productTile'));
    processInclude(require('./search/search'));
    processInclude(require('deborla/components/formValidator'));
    processInclude(require('deborla/components/bannerPageDesigner'));
    processInclude(require('deborla/components/loading-bar'));
    processInclude(require('deborla/priceSlider/priceSlider'));
    processInclude(require('deborla/components/contacts'));
    processInclude(require('./components/consent'));
    processInclude(require('deborla/einsteinCarousel'));
});

require('slick-carousel');
require('@fancyapps/fancybox');
require('lazysizes');
require('ion-rangeslider');
require('libphonenumber-js');

// sfra base includes
require('deborla/components/spinner');
