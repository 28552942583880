'use strict';

// Constants
const CONFIG = {
    SELECTOR: {
        BODY: 'body',
        MODAL_BACKGROUND: '.modal-background'
    },
    CLASS: {
        BODY_FIXER: 'popup-fixer'
    }
};

/**
 * Opens Modal
 * @param {JQuery<HTMLElement>} $modal - modal to open
 */
var show = function ($modal) {
    $($modal).show();
    $(CONFIG.SELECTOR.MODAL_BACKGROUND).show();
    $(CONFIG.SELECTOR.BODY).addClass(CONFIG.CLASS.BODY_FIXER);
};

/**
 * Closes Modal
* @param {JQuery<HTMLElement>} $modal - modal to close
 */
var hide = function ($modal) {
    $($modal).hide();
    $(CONFIG.SELECTOR.MODAL_BACKGROUND).hide();
    $(CONFIG.SELECTOR.BODY).removeClass(CONFIG.CLASS.BODY_FIXER);
};

module.exports = {
    show: show,
    hide: hide
};
