/* eslint-disable no-template-curly-in-string */

'use strict';

module.exports = function () {
    $('[data-language-selector]').each(function () {
        // Cache the number of options
        var $this = $(this),
            numberOfOptions = $(this).children('option').length;

        // Hides the select element
        $this.addClass('s-hidden');

        // Wrap the select element in a div
        $this.wrap('<div class="select"></div>');

        // Insert a styled div to sit over the top of the hidden select element
        $this.after('<div class="styled-select"></div>');

        // Cache the styled div
        var $styledSelect = $this.next('div.styled-select');

        var selectedItem;

        // Show the select option according to the current locale in the styled div
        for (var j = 0; j < numberOfOptions; j++) {
            if ($this.children('option').eq(j).attr('selected')) {
                selectedItem = $this.children('option').eq(j);
            }
        }

        // eslint-disable-next-line no-template-curly-in-string
        var styledItemImg = '<img class="country-flag" src="' + selectedItem.val() + '" alt="${country.name}" />';
        var styledItemText = '<span class="country-text">' + selectedItem.text() + '</span>';

        $styledSelect.html(styledItemImg + styledItemText);

        // Insert an unordered list after the styled div and also cache the list
        var $list = $('<ul />', {
            class: 'options'
        }).insertAfter($styledSelect);

        // Insert a list item into the unordered list for each select option
        for (var i = 0; i < numberOfOptions; i++) {
            var path = $this.children('option').eq(i).attr('data-locale-path');
            // eslint-disable-next-line no-template-curly-in-string
            var img = '<img class="country-flag" src="' + $this.children('option').eq(i).val() + '" alt="${country.name}" />';
            var text = '<span class="country-text">' + $this.children('option').eq(i).text() + '</span>';

            var linkElement = $('<a class="country-link" href="' + path + '" />').html(img + text);

            $('<li class="options-item" />').html(linkElement).appendTo($list);
        }

        // Cache the list items
        var $listItems = $list.children('li');

        // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
        $styledSelect.click(function (e) {
            e.stopPropagation();
            $('div.styled-select.active').each(function () {
                $(this).removeClass('active').next('ul.options').hide();
            });
            $(this).toggleClass('active').next('ul.options').toggle();
        });

        // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
        // Updates the select element to have the value of the equivalent option
        $listItems.click(function (e) {
            e.stopPropagation();
            $styledSelect.html($(this).html()).removeClass('active');
            $list.hide();
        });

        // Hides the unordered list when clicking outside of it
        $(document).click(function () {
            $styledSelect.removeClass('active');
            $list.hide();
        });
    });
};
