
$('body').on('focusin', '.form-control', function () {
    $(this).siblings('.form-control-label').addClass('focused');
    $(this).removeClass('is-invalid');
    $(this).siblings('.invalid-feedback').empty();
});

$('body').on('focusout', '.form-control', function () {
    if ($(this).val() === '') {
        $(this).siblings('.form-control-label').removeClass('focused');
    }
});

$('body').on('focusin', '.form-control', function () {
    $(this).siblings('.form-control-label').addClass('focused');
    $(this).removeClass('is-invalid');
    $(this).siblings('.invalid-feedback').empty();
});

$(document).ready(function () {
    $('.form-control').each(function () {
        if ($(this).attr('id') == 'confirmEmail' && $(this).is(':-webkit-autofill') || $(this).attr('id') == 'password' && $(this).is(':-webkit-autofill') || $(this).val() != '') {
            $(this).siblings('.form-control-label').addClass('focused');
        }
    });
});

$('body').on('change', '.form-control', function () {
    if ($(this).attr('id') == 'confirmEmail' && $(this).is(':-webkit-autofill') || $(this).attr('id') == 'password' && $(this).is(':-webkit-autofill') || $(this).val() != '') {
        $(this).siblings('.form-control-label').addClass('focused');
    }
});

$('body').on('change', '.form-control-subject-type', function (ev) {
    $('.form-control-subject').addClass('focused');
    if (ev.target.value === '') {
        $('.form-control-subject').removeClass('focused');
    }
});
