/* eslint-disable no-undef-init */
/* eslint-disable no-useless-escape */

'use strict';


module.exports = function () {
    /**
    * Show message after newsletter signup
    * @param {string} data - data returned from the server's ajax call
    * @param {Object} button - button that was clicked for email sign-up
    */
    function displayMessage(data, button) {
        var status;
        if (data.success) {
            status = 'alert-success';
        } else {
            status = 'alert-danger';
        }

        if ($('.email-signup-message').length === 0) {
            $('.newsletter-container-accept').append('<div class="email-signup-message"></div>');
        }

        if (data.msg2) {
            $('.email-signup-message').append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + ' <br> ' + data.msg2 + '</div>');
        } else {
            $('.email-signup-message').append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');
        }

        setTimeout(function () {
            $('.email-signup-message').remove();
            button.removeAttr('disabled');
        }, 3000);
    }

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        var checkbox = $('.newsletter-container-accept-checkbox').prop('checked');
        $.spinner().start();
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                checkbox: checkbox
            },
            success: function (data) {
                displayMessage(data, button);
                $.spinner().stop();
            },
            error: function (err) {
                displayMessage(err, button);
                $.spinner().stop();
            }
        });
    });

    // Footer Cookie - on close (Cross Icon Click)
    $('[data-footer-close]').on('click', function () {
        $('body').addClass('footer-cookie--hidden');
        setTimeout(function () {
            $('.footer-cookie').addClass('visually-hidden');
        }, 400);
        document.cookie = 'show_cookie_message=1; path=/';
    });

    // Footer Cookie Hide
    if (document.cookie.indexOf('show_cookie_message=1') < 0) {
        $('body').removeClass('footer-cookie--hidden');
        setTimeout(function () {
            $('.footer-cookie').removeClass('visually-hidden');
        }, 400);
    }
    // i need this function because MC dont validade errors on BackEnd

    // show sucess if the customer subscribed with sucess...
    $(document).ready(function () {
        var sucessSpan = document.getElementById('sucessSubs');
        var error = document.getElementById('error');
        if (error != undefined) {
            error.textContent = '';
        }
        if (sucessSpan != undefined) {
            sucessSpan.textContent = '';

            var sucessMsg = '';
            var isSucess = document.getElementById('error-sucess-data').getAttribute(
                'data-sucess-subscribed');
            // if the checkbox is not selected show error
            if (isSucess == 'true' || isSucess == true) {
                sucessMsg = document.getElementById('error-sucess-data').getAttribute(
                    'data-sucess');
                sucessSpan.textContent = sucessMsg;
                sucessSpan.focus();
                return;
            }
        }
    });
};
