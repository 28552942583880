'use strict';

module.exports = ({
    dataTabSelector = '[data-tab]'
} = {}) => {
    $(dataTabSelector).on('click', function () {
        if (!$(this).hasClass('active')) {
            // Remove class Active from other Tabs
            $(dataTabSelector).removeClass('active');

            // Set Tab Header Item as Active
            $(this).addClass('active');

            // Set Tab Content Item as Active
            var clickedTab = $(this).data('tab');
            $('[data-tab=' + clickedTab + ']').addClass('active');
        }
    });
};
