'use strict';

module.exports = ({
    collapsibleHeaderSelector = '[data-collapsible-header]',
    collapsibleSelector = '[data-collapsible]'
} = {}) => {
    $(collapsibleHeaderSelector).on('click', function (e) {
        e.preventDefault();
        $(this).closest(collapsibleSelector).toggleClass('active');
    });
};
