'use strict';

var base = require('base/search/search');
var states = require('improove/util/states');

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 * @return {undefined}
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}

/**
 * Update sort option URLs from Ajax response
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function updateSortOptions(response) {
    var $tempDom = $('<div>').append($(response));
    var sortOptions = $tempDom.find('.grid-footer').data('sort-options').options;
    sortOptions.forEach(function (option) {
        $('option.' + option.id).val(option.url);
    });
}

/**
 * @private
 * @function
 * @description append Page Size to URL
 * @param {Object} The load more products button element
 */
function appendPageSize(loadBtnElem) {
    var loadingButton = $(loadBtnElem),
        gridUrl = loadingButton.attr('data-url');

    var szString = 'sz=',
        startString = '&start=';

    // Set Page Size value from Load More Button
    var startSubString = gridUrl.substr(gridUrl.indexOf(startString) + startString.length);
    var counter = startSubString.indexOf('&');
    var sizeString = startSubString.substr(startSubString, counter),
        sizeValue = parseInt(sizeString, 10);

    var url = '';

    if (window.location.search.indexOf('?') >= 0) {
        if (window.location.search.indexOf(szString) >= 0) {
            // Replace Page Size value in the URL
            var sizeIndex = window.location.search.indexOf(szString),
                startIndex = window.location.search.indexOf(startString);

            url = window.location.search.substr(0, sizeIndex + 3) + sizeValue + window.location.search.substr(startIndex);

            history.replaceState(null, document.title, url);
        } else {
            // Append Page Size value to the URL (the URL has filters parameters)
            url = window.location.pathname + window.location.search + '&' + szString + sizeValue + startString + '0';

            history.pushState(null, document.title, url);
        }
    } else {
        // Append Page Size value to the URL
        url = window.location.pathname + window.location.search + '?' + szString + sizeValue + startString + '0';

        history.pushState(null, document.title, url);
    }
}

function validateWishlist() {

	var products = $('.product');
	var lengthProducts = $(products).length;

	for(var i=0; i < lengthProducts; i++) {
		var url = $($(products)[i]).find('.wishlistTile').attr('data-href');
		var idProduct = $($(products)[i]).find('.wishlistTile').attr('data-idProductWishlist');

		var isVisited = $($(products)[i]).find('.icon-full-heart').length;

		if(url && isVisited === 0) {
			$.ajax({
				url: url,
				type: 'post',
				async: false,
				dataType: 'json',
				data: {
					'idproduct' :idProduct
				},
				success: function (data) {
						$($(products)[i]).find('.wishlistTile span').addClass('heart-active');

						if(data.isInWishlist) {
							$($(products)[i]).find('.wishlistTile span').addClass('icon-full-heart');
                            $($(products)[i]).find('.wishlistTile span').removeClass('icon-wishlist');
						}
				}
			});
		}
	}

}
$(window).on('load', function(){
    validateWishlist();

});


module.exports = {
    base: base,

    sort: function () {
        // Handle sort order menu selection
        $('.container').on('change', '[name=sort-order]', function (e) {
            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:sort', this.value);
            $.ajax({
                url: this.value,
                data: { selectedUrl: this.value },
                method: 'GET',
                success: function (response) {
                    $('.product-grid').empty().html(response);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },

    showMore: function () {
        // Show more products
        $('.container').on('click', '.show-more', function (e) {
            e.stopPropagation();
            var showMoreUrl = $('.button-show-more').data('url');

            e.preventDefault();

            $.spinner().start();
            $(this).trigger('search:showMore', e);
            $.ajax({
                url: showMoreUrl,
                data: { selectedUrl: showMoreUrl },
                method: 'GET',
                success: function (response) {
                    $('.grid-footer').replaceWith(response);
                    updateSortOptions(response);
                    $.spinner().stop();

                    appendPageSize('.button-show-more');
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });

    },

    statesSearch: function () {
        // Handle refinement value selection and reset click
        var collapsibleHeaderSelector = '[data-search-collapsible-header]';
        var collapsibleSelector = '[data-search-collapsible]';

        $('body').on('click', collapsibleHeaderSelector, function (e) {
            e.preventDefault();
            $(this).closest(collapsibleSelector).toggleClass('active');
        });
    },

    statesFilterSearch: function () {
        var filterStateSelector = '[data-search-filter-mobile-trigger]';

        $('body').on('click', filterStateSelector, function (e) {
            e.preventDefault();
            $('body').toggleClass('state--refinement');
        });
    },

    applyFilterPrice: function () {
        // Handle refinement value selection and reset click
        $('body').on('filter:priceChange', function (e) {
            e.preventDefault();
            e.stopPropagation();
            $.spinner().start();
            $('js-range-slider').trigger('search:filter', e);
            $.ajax({
                url: $('.js-range-slider').data('href'),
                data: {
                    page: $('.grid-footer').data('page-number'),
                    selectedUrl: $('.js-range-slider').data('href')
                },
                method: 'GET',
                success: function (response) {
                    parseResults(response);
                    $.spinner().stop();
                    validateWishlist();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
    },
    initSearch({
        stateRefinement = 'refinement',
        refinementContainerSelector = '.state-container--refinements',
        refinementTriggerSelector = '.refinements-state-trigger',
        applyFilterSelector = '.refinements li button, .refinement-bar button.reset, .filter-value button, .swatch-filter button',
        applyFilterContainer = '.container',
        statesUtil = states
    } = {}) {
        const $refinementStateVariable = stateRefinement;
        const $refinementContainerElement = $(refinementContainerSelector);
        const $refinementTriggerElement = $(refinementTriggerSelector);
        const $applyFilterContainer = $(applyFilterContainer);

        statesUtil.add($refinementStateVariable, $refinementContainerElement, $refinementTriggerElement, {});


        $applyFilterContainer.on(
            'click',
            applyFilterSelector, function (e) {
                e.preventDefault();
                e.stopPropagation();
                $.spinner().start();
                $(this).trigger('search:filter', e);
                $.ajax({
                    url: $(this).data('href'),
                    data: {
                        page: $('.grid-footer').data('page-number'),
                        selectedUrl: $(this).data('href')
                    },
                    method: 'GET',
                    success: (response) => {
                        window.scrollTo(0, 0);
                        parseResults(response);
                        $.spinner().stop();
                    },
                    error: () => {
                        $.spinner().stop();
                    }
                });
            });
    }
};
