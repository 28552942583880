'use strict';

// Imports
var modal = require('../utils/modalUtils');

// Constants
const CONFIG = {
    ID: {
        MODAL: '#consentModal',
        FORM: '#consent-tracking-form',
        CONTENT_PAGE_DATA: '#protecaodedados',
        CONTENT_PAGE_CARD: '#termosecondicoesdocartao',
        MANDATORY_CHECKBOX: '#read-and-accept-policies',
        MANDATORY_HAPPY_LOYALTY_CARD: '#authorize-loyaltycard'
    },
    CLASS: {
        INVALID: 'is-invalid'
    }
};

module.exports = function () {
    /**
     * Submit Consent tracking to be saved in customer profile and sychronize with Service Cloud.
     * @param {Object} e - element that fires submit.
     */
    $(CONFIG.ID.FORM).on('submit', function (e) {
        e.preventDefault();
        var isToSubmit = true;

        if ($(CONFIG.ID.MANDATORY_CHECKBOX).length && !$(CONFIG.ID.MANDATORY_CHECKBOX).is(':checked')) {
            isToSubmit = false;
            $(CONFIG.ID.MANDATORY_CHECKBOX).addClass(CONFIG.CLASS.INVALID);
        }

        if (
            $(CONFIG.ID.MANDATORY_HAPPY_LOYALTY_CARD).length &&
            !$(CONFIG.ID.MANDATORY_HAPPY_LOYALTY_CARD).is(':checked')
        ) {
            isToSubmit = false;
            $(CONFIG.ID.MANDATORY_HAPPY_LOYALTY_CARD).addClass(CONFIG.CLASS.INVALID);
        }

        if (isToSubmit) {
            var $form = $(this);
            var url = $form.attr('action');

            if (!url) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: $form.serialize(),
                success: function () {
                    modal.hide(CONFIG.ID.MODAL);
                    $.spinner().stop();
                },
                error: function (error) {
                    $.spinner().stop();
                    // eslint-disable-next-line
                    console.log(error);
                }
            });
        }
    });

    /**
     * Checks client side conditions to
     * display the consent tracking modal
     */
    if (
        $(CONFIG.ID.MODAL).length &&
        $(CONFIG.ID.CONTENT_PAGE_DATA).length === 0 &&
        $(CONFIG.ID.CONTENT_PAGE_CARD).length === 0
    ) {
        modal.show(CONFIG.ID.MODAL);
    } else {
        modal.hide(CONFIG.ID.MODAL);
    }
};
