'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon) {
    $.spinner().stop();
    var status = data.success && data.pid ? 'alert-success' : 'alert-danger';

    var messageContainer = icon.closest('.image-container').find('.add-to-wishlist-messages');
    if (messageContainer.length === 0) {
        messageContainer = $('<div class="add-to-wishlist-messages"></div>');
        icon.closest('.image-container').append(messageContainer);
    }

    // Clean messages in case there was any to avoid overlap
    messageContainer.empty();

    // Create and add new message
    var message = $('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');
    messageContainer.append(message);

    // Set up timer to delete message after 3 seconds
    setTimeout(function () {
        message.fadeOut(300, function() { 
            $(this).remove();
            // If there is no more messages then remove container
            if (messageContainer.children().length === 0) {
                messageContainer.remove();
            }
        });
    }, 3000);

    // Change icon to favorite state
    if (data.success && data.pid) {
        icon.toggleClass('icon-wishlist icon-full-heart');
    }
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.wishlistTile', function (e) {
            e.preventDefault();
            var icon = $(this).find($('[data-wishlist-icon]'));
            var url = icon.hasClass('icon-wishlist') ? $(this).attr('href') : $(this).attr('data-remove');
            var type = icon.hasClass('icon-wishlist') ? 'post' : 'get';
            var pid = $(this).closest('.product').data('pid');
            var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            if (!url || !pid) {
                return;
            }

            $.spinner().start();
            $.ajax({
                url: url,
                type: type,
                dataType: 'json',
                data: {
                    pid: pid,
                    optionId: optionId,
                    optionVal: optionVal
                },
                success: function (data) {
                     displayMessageAndChangeIcon(data, icon);
                },
                error: function (err) {
                    displayMessageAndChangeIcon(err, icon);
                }
            });
        });
    }
};
