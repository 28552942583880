'use strict';

var Popups = window.Popups;

// Keep a global list of all popups
window.Popups = window.Popups || {
    list: [],
    removeAll: function removeAll() {
        for (var i = 0; i < Popups.list.length; i++) {
            Popups.list[i].hide();
        }
    }
};

/**
* Get or create container
* @return {Object} - container
*/
function getOrCreateContainer() {
    var container = document.querySelector('body .popupcontainer');
    var containerBackground = document.querySelector('body .popupcontainer .popupcontainer-background');
    if (!container) {
        container = document.createElement('div');
        container.classList.add('popupcontainer');
        containerBackground = document.createElement('div');
        containerBackground.classList.add('popupcontainer-background');

        document.querySelector('body').appendChild(container);
        document.querySelector('body').appendChild(containerBackground);
    }

    return container;
}
getOrCreateContainer();

/**
* Get or create container
* @param {string} html - Html of the popup
* @param {string} options - aditional options
*/
function Popup(html, options) {
    options = options || {};

    var thisPopup = this;
    var desktopBackground = typeof options.desktopBackground === 'undefined' ? true : options.desktopBackground;
    var ajaxForm = typeof options.ajaxForm === 'undefined' ? false : options.ajaxForm;

    this.active = false;

    // Classes that should be applied to body when this popup is active
    this.bodyClasses = ['active-popup'];

    if (desktopBackground) {
        this.bodyClasses.push('popup-background');
    }

    // Used to save lookups in the future
    this.body = document.querySelector('body');

    // Get or create the container that the popup is inserted into
    this.container = getOrCreateContainer();

    /**
    * Hide and remove modal node
    */
    function hideModal() {
        if (thisPopup.active) {
            thisPopup.container.removeChild(thisPopup.popup);
            thisPopup.active = false;

            // Remove from global list
            window.Popups.list.splice(window.Popups.list.indexOf(thisPopup));

            // Remove classes from body
            for (var i = 0; i < thisPopup.bodyClasses.length; i++) {
                thisPopup.body.classList.remove(thisPopup.bodyClasses[i]);
            }
        }
    }

    this.container.addEventListener(
        'click',
        function (e) {
            if (e.target === this.container) {
                hideModal();
            }
        }.bind(this)
    );

    // Create the popup
    this.popup = document.createElement('div');
    this.popup.classList.add('popup');
    this.popup.innerHTML = html;
    this.closeButton = document.createElement('div');
    this.closeButton.classList.add('close-popup');
    this.closeButton.innerHTML = '<div class="icon-cross"></div>';
    this.closeButton.addEventListener('click', hideModal);
    this.popup.appendChild(this.closeButton);

    if (options.size == 'fullscreen') {
        this.popup.classList.add('popup-fullscreen'); 
    }

    // Intercepts outgoing forms and makes them as ajax requests instead
    if (ajaxForm) {
        this.bindForms();
    }
}

Popup.prototype.bindForms = function () {
    var forms = this.popup.querySelectorAll('form');
    for (var i = 0; i < forms.length; i++) {
        this.bindForm(forms[i]);
    }
};

Popup.prototype.bindForm = function (form) {
    form.addEventListener(
        'submit',
        function (e) {
            e.preventDefault();

            var data = $(form).serialize();

            // Check for submit buttons and include in the request
            var submitButton = $(form).find('button[type=submit], input[type=submit]');
            if (submitButton.length) {
                data += '&' + submitButton.attr('name') + '=' + encodeURIComponent(submitButton.attr('value'));
            }

            $.ajax({
                url: $(form).attr('action') + '?format=ajax', // This should be made in a better way
                type: $(form).attr('method') || 'get',
                data: data,
                success: function (html) {
                    this.updateHTML(html);
                    this.bindForms();
                }.bind(this)
            });
        }.bind(this)
    );

    var cancels = form.querySelectorAll('.cancel, .cancel-button');
    for (var i = 0; i < cancels.length; i++) {
        cancels[i].addEventListener(
            'click',
            function (e) {
                e.preventDefault();
                this.hide();
            }.bind(this)
        );
    }
};

Popup.prototype.addClass = function (name) {
    this.popup.classList.add(name);
};

Popup.prototype.removeClass = function (name) {
    this.popup.classList.remove(name);
};

Popup.prototype.show = function () {
    var i;
    if (!this.active) {
        // Remove any other popup
        for (i = 0; i < window.Popups.list.length; i++) {
            Popups.list[i].hide();
        }

        // Add to global list
        window.Popups.list.push(this);

        this.container.appendChild(this.popup);
        this.active = true;

        // Apply classes to body
        for (i = 0; i < this.bodyClasses.length; i++) {
            this.body.classList.add(this.bodyClasses[i]);
        }
    }
};

module.exports = {
    Popup: Popup,
    Popups: Popups
};
