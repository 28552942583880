'use strict';

// Util includes
var states = require('improove/util/states');

// Cart include
var cart = require('../cart/cart');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessageAndRemoveFromCart(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages "></div>');
    }
    $('.add-to-wishlist-messages')
        .append('<div class="add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 1500);
    var $targetElement = $('a[data-pid="' + data.pid + '"]').closest('.product-info').find('.remove-product');
    var itemToMove = {
        actionUrl: $targetElement.data('action'),
        productID: $targetElement.data('pid'),
        productName: $targetElement.data('name'),
        uuid: $targetElement.data('uuid')
    };
    $('body').trigger('afterRemoveFromCart', itemToMove);
    setTimeout(function () {
        $('.cart.cart-page #removeProductModal').modal();
    }, 2000);
}

/**
 * move items from Cart to wishlist
 * returns json object with success or error message
 */
function moveToWishlist() {
    $('body').on('click', '.product-move .move', function (e) {
        e.preventDefault();
        var url = $(this).attr('href');
        var pid = $(this).data('pid');
        var optionId = $(this).closest('.product-info').find('.lineItem-options-values').data('option-id');
        var optionVal = $(this).closest('.product-info').find('.lineItem-options-values').data('value-id');
        optionId = optionId || null;
        optionVal = optionVal || null;
        if (!url || !pid) {
            return;
        }

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                pid: pid,
                optionId: optionId,
                optionVal: optionVal
            },
            success: function (data) {
                displayMessageAndRemoveFromCart(data);
            },
            error: function (err) {
                displayMessageAndRemoveFromCart(err);
            }
        });
    });
}

/**
 * Attach multiple event listeners, related with the minicart states:
 * 1 - Update minicart on add to cart
 * 2 - Update minicart item count
 * 3 - Update minicart on bonus product parent change
 */
function addStates() {
    cart();

    /**
     * 1 -Update minicart on add to cart
     */
    $('body').on('product:afterAddToCart', function () {
        var url = $('.minicart').data('action-url');
        $.get(url, function (data) {
            $('.state-cart-container').empty();
            $('.state-cart-container').append(data);
        });
    });

    states.add('cart', $('.state-container--cart'), $('.cart-state-trigger'), {});

    /**
     * 2 - Update minicart item count
     */
    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            if (count.quantityTotal > 0) {
                $('[data-minicart-icon]').removeClass();
                $('[data-minicart-icon]').addClass('icon-bag-full');
            } else {
                $('[data-minicart-icon]').removeClass();
                $('[data-minicart-icon]').addClass('icon-shopping-bag');
            }
        }
    });

    /**
     * 3 - Update minicart on bonus product parent change
     */
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });
}

/**
 * Add event listeners to quantity buttons
 * 1 - Decrease quantity
 * 2 - Increase quantity
 */
function quantityButtonListener() {
    $('body').on('click', '.alert .close', function () {
        $(this).closest('.alert').addClass('hide');
    });

    /**
     * 1 - Decrease quantity
     */
    $('body').on('click', '[data-quantity-decrease]', function () {
        var $button = $(this);
        var oldValue = $button.siblings('[data-quantity]').val();
        var newVal;

        if (oldValue > 1) {
            newVal = parseInt(oldValue, 10) - 1;
        } else {
            newVal = 1;
        }

        $button.siblings('[data-quantity]').val(newVal);
        $button.siblings('[data-quantity]').change();
    });

    /**
     * 2 - Increase quantity
     */
    $('body').on('click', '[data-quantity-increase]', function () {
        var $button = $(this);
        var oldValue = $button.siblings('[data-quantity]').val();
        var newVal;

        newVal = parseInt(oldValue, 10) + 1;

        if (oldValue < 100) {
            newVal = parseInt(oldValue, 10) + 1;
        } else {
            newVal = 100;
        }

        $button.siblings('[data-quantity]').val(newVal);
        $button.siblings('[data-quantity]').change();
    });
}

module.exports = {
    moveToWishlist: moveToWishlist,
    addStates: addStates,
    quantityButtonListener: quantityButtonListener
};
